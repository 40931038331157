import styled from 'styled-components';

const ContactPageStyles = styled.div`
  align-items: flex-start;
  background: ${props => props.theme.white};
  color: ${props => props.theme.lightblack};
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: calc(
    ${props => props.theme.maxWidth} - ${props => props.theme.gutterLg} * 2
  );
  padding: 5rem;
  width: 100%;

  .contact-header {
    color: ${props => props.theme.lightblack};
    margin-bottom: 3.5rem;
    margin: auto auto 3.5rem;
    text-align: left;
    width: 100%;

    h2 {
      color: ${props => props.theme.blue};
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .contact-information {
    margin-top: 3.75rem;
    width: 35rem;

    & > div {
      margin-bottom: 3rem;
    }

    h3 {
      margin-bottom: 1.25rem;
    }

    span {
      display: block;
      margin-bottom: 0.3rem;
    }

    a {
      color: ${props => props.theme.darkgrey};
    }
  }

  .address-header {
    margin-bottom: 1rem;
  }

  .hours-wrapper p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .contact-form {
    align-items: flex-start;
    color: inherit;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    margin: 0;
    padding: 0 0 2.5rem;
    text-align: center;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: calc(100% - 40rem);
    }

    h1 {
      margin: 0;
    }
  }
`;

export default ContactPageStyles;
