import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
// import isEmail from 'validator/lib/isEmail';

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join(`&`);

export default function ContactForm() {
  const [state, setState] = useState({
    name: ``,
    email: ``,
    message: ``,
    'bot-field': ``,
  });

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch(`/`, {
      method: `POST`,
      headers: { 'Content-Type': `application/x-www-form-urlencoded` },
      body: encode({ 'form-name': form.getAttribute(`name`), ...state }),
    })
      .then(() => navigate(form.getAttribute(`action`)))
      .catch(error => alert(error));
  };

  return (
    <ContactFormWrapper
      name="contact"
      method="post"
      action="/contact-us/thank-you"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div>
        <label htmlFor="name">
          Full Name
          <input
            type="text"
            id="name"
            name="name"
            value={state.name}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label htmlFor="email">
          Email
          <input
            type="email"
            id="email"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
        </label>
      </div>
      <p className="hidden">
        <label htmlFor="botField">
          Do not fill this out
          <input
            id="botField"
            name="bot-field"
            value={state[`bot-field`]}
            onChange={handleChange}
          />
        </label>
      </p>
      <div>
        <label htmlFor="message">
          Message
          <textarea
            type="text"
            id="message"
            name="message"
            value={state.message}
            onChange={handleChange}
          />
        </label>
      </div>

      <div id="submitBtn">
        <button type="submit" aria-label="Send Message">
          Send Message
        </button>
      </div>
    </ContactFormWrapper>
  );
}

const ContactFormWrapper = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;

  p.hidden {
    display: none;
  }

  *:not(button) {
    color: ${props => props.theme.lightblack};
    margin: 0.5rem 0;
  }

  div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  label {
    text-align: left;
    width: 100%;
  }

  label .error {
    color: ${props => props.theme.red};
    font-weight: 500;
    margin: 0 0 1rem;
  }

  input {
    border: none;
    height: 4rem;
    margin-bottom: 1rem;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 100rem ${props => props.theme.offwhite} inset;
    -webkit-box-shadow: 0 0 0 100rem ${props => props.theme.offwhite} inset;
  }

  textarea {
    border: none;
    height: 15rem;
    resize: none;
  }

  input,
  textarea {
    background: ${props => props.theme.white};
    border: 0.1rem solid ${props => props.theme.lightgrey};
    border-radius: 0.2rem;
    outline: none;
    padding: 0.5rem 1rem;
    width: 100%;

    &:focus {
      background: ${props => props.theme.offwhite};
    }
  }

  #submitBtn {
    width: 20rem;
  }

  button[type='submit'] {
    background: ${props => props.theme.blue};
    border-radius: ${props => props.theme.br};
    border: none;
    color: ${props => props.theme.white};
    cursor: pointer;
    height: 4rem;
    margin-top: 1.5rem;
    outline: none;
    padding: 0.5rem 1rem;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background: ${props => props.theme.darkblue};
    }

    &:not(:disabled):focus {
      background: ${props => props.theme.lightblue};
      box-shadow: 0 0 0.2rem 0.4rem ${props => props.theme.clearblue};
    }
  }
`;
